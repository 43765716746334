<template>
  <section id="games">
    <div>
      <b-modal
        :active.sync="isLoading"
        :width="400"
        :can-cancel="[false, false, false]"
      >
        <div class="modal-card" style="width: auto">
          <section class="modal-card-body">
            <div class="field" style="margin-top: 25px;">
              <p style="margin-bottom: 15px; text-align: center;">
                Carregando informações, por favor, não recarregue a página até
                que o processo seja concluído.
              </p>
              <progress class="progress is-small is-primary" max="100"
                >15%</progress
              >
            </div>
          </section>
        </div>
      </b-modal>
    </div>

    <div class="columns has-background-bluedark2 is-variable is-gapless">
      <div class="column">
        <b-navbar mobile-burger type="is-bluedark2">
          <template slot="start">
            <b-navbar-item
              tag="div"
              @click="filterStatus('destaque')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-purple': selectStatus == 'destaque' }"
                >Destaque</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('pre_venda')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-info': selectStatus == 'pre_venda' }"
                >Pré Venda</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('aberta')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-success': selectStatus == 'aberta' }"
                >Aberta</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('em_andamento')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-warning': selectStatus == 'em_andamento' }"
                >Em Andamento</b
              >
            </b-navbar-item>

            <b-navbar-item
              tag="div"
              @click="filterStatus('encerrada')"
              style="cursor: pointer"
            >
              <b :class="{ 'tag is-danger': selectStatus == 'encerrada' }"
                >Encerrada</b
              >
            </b-navbar-item>
          </template>

          <template slot="end">
            <b-navbar-item tag="div" style="cursor: pointer">
              <b-button v-if="gamesTable" type="is-success" @click="addGame()"
                >Adicionar MiniGame</b-button
              >
              <b-button
                v-if="gamesTable && envMode == 'development'"
                type="is-warning"
                @click="nextRound()"
                >Avançar da Rodada {{ this.mercado.rodada_atual }} para
                {{
                  this.mercado.rodada_atual == 38
                    ? 1
                    : this.mercado.rodada_atual + 1
                }}</b-button
              >
              <b-button
                v-if="
                  gamesForm && 'add,edit,copy'.indexOf(gamesFormAction) !== -1
                "
                type="is-success"
                @click="saveGame()"
                >Salvar</b-button
              >
              <b-button
                v-if="gamesFormLevel2"
                type="is-purple"
                @click="redoGame()"
                >Excluir Premiação</b-button
              >
              <b-button
                v-if="gamesForm || gamesFormLevel2"
                type="is-danger"
                outlined
                @click="resetForm()"
                >Cancelar</b-button
              >
            </b-navbar-item>
          </template>
        </b-navbar>
      </div>
    </div>

    <div class="has-background-bluedark2">

      <b-table
        blueDark2
        :id="`table-games-${selectStatus}`"
        v-if="gamesTable"
        :loading="isLoading"
        :data="games"
        :mobile-cards="true"
        :draggable="'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1"
        @dragstart="dragstart"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
        detailed
        detail-key="id"
        :show-detail-icon="true"
        :default-sort="
          ('pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1) ? 'ordem' : ''
        "
        :default-sort-direction="
          ('pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1) ? 'asc' : ''
        "
      >
        <template slot-scope="props" slot="header">
          <div 
            v-if="props.column.sortable" 
            class="label-table centralize-row"
          >
            <span v-if="props.column.sortable">
              <b-icon icon="sort" pack="fas" size="is-small"></b-icon>
            </span>
            <p>{{ props.column.label }}</p>
          </div>
          <div 
            v-else 
            class="label-table centralize-row"
          >
            <p>{{ props.column.label }}</p>
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column label="" width="100">
            <div class="columns is-multiline">
              <div class="column is-full">
                <b-button
                  type="is-primary"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="search"
                  outlined
                  @click="viewGame(props.row)"
                />
                <b-button
                  type="is-info"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="copy"
                  outlined
                  @click="copyGame(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos || props.row.times_inscritos == 0)
                  "
                  type="is-warning"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="edit"
                  outlined
                  @click="editGame(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos || props.row.times_inscritos == 0)
                  "
                  type="is-danger"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="times"
                  outlined
                  @click="delGame(props.row)"
                />
                <b-button
                  v-if="
                    'aberta,pre_venda'.indexOf(props.row.status) !== -1 &&
                      (!props.row.times_inscritos || props.row.times_inscritos == 0)
                  "
                  type="is-purple"
                  icon-pack="fas"
                  size="is-small"
                  icon-right="trophy"
                  outlined
                  @click="delGameLevel2(props.row)"
                />
              </div>
              <div class="column is-full">
                <b-switch 
                  type="is-success" 
                  v-model="props.row.ativo" 
                  @input="toggleFlag(props.row)"
                >Ativo</b-switch>
                <b-switch
                  v-if="!userInfo.isInfluencer"
                  type="is-warning"
                  v-model="props.row.destaque"
                  @input="toggleDestaque(props.row)"
                >Destaque</b-switch>
                <b-switch
                  type="is-warning"
                  v-model="props.row.destaque_influencer"
                  @input="toggleDestaqueInfluencer(props.row)"
                >Destaque Influencer</b-switch>
              </div>
            </div>
          </b-table-column>

          <b-table-column
            v-if="'pre_venda,aberta,destaque'.indexOf(selectStatus) !== -1"
            field="ordem"
            label="Ordem"
            sortable
            right
            numeric
          >
            {{ props.row.ordem }}
          </b-table-column>

          <b-table-column field="id" label="Id" sortable right numeric>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column
            field="influencer.nome_midia"
            label="Influenciador"
            sortable
            searchable
            left
          >
            {{ props.row.influencer.nome_midia }}
          </b-table-column>

          <b-table-column
            field="game_tipo.descricao"
            label="Tipo"
            sortable
            searchable
            left
          >
            {{ props.row.game_tipo.descricao }}
          </b-table-column>

          <b-table-column label="Parâmetros" left width="400">
            <div class="columns is-multiline is-gapless">
              <div class="column is-full">
                <label class='subtitle'>Scouts:</label> {{ props.row.scouts }}
              </div>
              <div class="column is-full">
                <label class='subtitle'>Posições:</label> {{ props.row.posicoes.replace(/,/g, ', ') }}
              </div>
              <div class="column is-full">
                <label class='subtitle'>Atletas:</label> {{ props.row.qtd_atletas }}
              </div>
              <div class="column is-full">
                <label class='subtitle'>Cartoletas:</label> {{ props.row.qtd_cartoletas }}
              </div>
              <div class="column is-full">
                <label class='subtitle'>Scouts:</label> {{ props.row.apuracao_tipo_scouts.descricao }} / {{ props.row.rank_tipo_scouts.descricao }}
              </div>
              <div class="column is-full">
                <label class='subtitle'>Desempate:</label> {{ props.row.apuracao_tipo_scouts_desempate.descricao }} / {{ props.row.rank_tipo_scouts_desempate.descricao }}
              </div>
            </div>
          </b-table-column>

          <b-table-column field="rodada_inicio" label="Rodada" sortable searchable right numeric>
            <div>
              {{ props.row.rodada_inicio }} - {{ props.row.rodada_final }}
            </div>
          </b-table-column>

          <b-table-column field="status" label="Status" sortable centered>
            <span
              :class="{
                'tag is-danger': props.row.status == 'encerrada',
                'tag is-warning': props.row.status == 'em_andamento',
                'tag is-success': props.row.status == 'aberta',
                'tag is-info': props.row.status == 'pre_venda',
              }"
            >
              {{ props.row.status }}
            </span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <table>
            <thead>
              <th>Id</th>
              <th>Título</th>
              <th>Valores</th>
              <th>Limites</th>
              <th>Parâmetros</th>
              <!-- <th>Id</th>
              <th>Salas</th>
              <th>Inscrição</th>
              <th>%Escala</th>
              <th>%Influenciador</th>
              <th>Qtd.Prevista</th>
              <th>Limite Inscrições</th>
              <th>Limite Inscrições Usuário</th>
              <th>Limite Reserva</th>
              <th>Senha</th>
              <th>Capitão</th>
              <th>Clonagem</th> -->
            </thead>
            <tbody>
              <tr v-for="sala of props.row.salas" :key="sala.id_sala">
                <td>{{ sala.id_sala }}</td>
                <td>{{ sala.titulo }}</td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div class="column is-full">
                      <label class='subtitle'>Inscrição:</label> {{ sala.valor }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>%Escala:</label> {{ sala.percent_escala }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>%Influenciador:</label> {{ sala.percent_sala }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div class="column is-full">
                      <label class='subtitle'>Qtd.Prevista:</label> {{ sala.prev_qtd_time }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Limite Inscrições:</label> {{ sala.max_qtd_time }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Limite Inscrições Usuário:</label> {{ sala.max_qtd_time_pssa }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Limite Reserva:</label> {{ formatDate(sala.limite_reserva) }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="columns is-multiline is-gapless">
                    <div v-if="edit.sala && edit.sala.id_sala == sala.id_sala" class="column is-full">
                      <b-field label="Categorias" label-position="on-border">
                        <v-select class="form-select multiple-select" multiple placeholder="Categorias" :options="opcCategorias" v-model="edit.sala.categorias">
                          
                        </v-select>
                        <p 
                          class="control centralize" 
                          style="padding: 0.5em;"
                          @click="saveSalaCategorias(sala)"
                        >
                          <b-icon
                            type="is-success"
                            pack="fas"
                            size="is-small"
                            icon="check"
                          />
                        </p>
                        <p 
                          class="control centralize" 
                          style="padding: 0.5em;"
                          @click="cancelSalaCategorias()"
                        >
                          <b-icon
                            type="is-danger"
                            pack="fas"
                            size="is-small"
                            icon="times"
                          />
                        </p>
                      </b-field>
                    </div>
                    <div 
                      v-else 
                      class="column is-full" 
                      @click="editSalaCategorias(sala)"
                    >
                      <label class='subtitle'>Categorias:</label> 
                      {{ formatCategorias(sala.categorias) }}
                      <span style="padding: 0.5em;">
                        <b-icon
                          type="is-warning"
                          pack="fas"
                          size="is-small"
                          icon="edit"
                        />
                      </span>
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Senha:</label> {{ sala.senha }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Capitão:</label> {{ sala.capitao == 1 ? "Sim" : "Não" }}
                    </div>
                    <div class="column is-full">
                      <label class='subtitle'>Clonagem:</label> {{ sala.clonagem == 1 ? "Sim" : "Não" }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template slot="empty">
            <section class="section">
                <div class="content has-text-grey has-text-centered">
                    <p>
                        <b-icon
                            icon="emoticon-sad"
                            size="is-large">
                        </b-icon>
                    </p>
                    <p>Nenhum Registro.</p>
                </div>
            </section>
        </template>
      </b-table>
    </div>  

    <div 
      v-if="gamesForm" 
      class="columns is-multiline is-gapless has-background-bluedark2"
    >
      <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Dados Gerais" icon="info" icon-pack="fas">
          <div class="columns is-multiline">

            <div class="column is-1">
              <b-field label="Ativo?">
                <b-switch type="is-success" v-model="form.ativo"></b-switch>
              </b-field>
            </div>

            <div class="column is-2">
              <b-field label="Influencer">
                <b-select 
                  v-model="form.id_influencer" 
                  required 
                  :disabled="gamesFormAction == 'view'"
                  @input="setInfluencer()"
                >
                  <option
                    v-for="op of influencers"
                    :key="op.id"
                    :value="op.id">
                    {{ op.nome_midia }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Tipo">
                <b-select
                  v-model="form.id_game_tipo"
                  @input="changeType()"
                  required
                  :disabled="gamesFormAction == 'view'"
                >
                  <option v-for="op of tipo_game" :key="op.slug" :value="op.id">
                    {{ op.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column" v-if="form.id_game_tipo == 2">
              <b-field label="Mês Inicial">
                <b-select
                  v-model="form.mes"
                  @input="setRodadas(form.mes)"
                  :disabled="gamesFormAction == 'view'"
                >
                  <option
                    v-for="(rodada, index) of rodadas"
                    :key="index"
                    :value="rodada.mes_rodada"
                  >
                    {{ rodada.mes_extenso }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column" v-if="form.id_game_tipo == 2">
              <b-field label="Mês Final">
                <b-select
                  v-model="form.mes_final"
                  :disabled="gamesFormAction == 'view'"
                >
                  <option
                    v-for="(rodada, index) of rodadas"
                    :key="index"
                    :value="rodada.mes_rodada"
                  >
                    {{ rodada.mes_extenso }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div
              class="column"
              v-if="
                form.id_game_tipo == 1 ||
                form.id_game_tipo == 3 ||
                form.id_game_tipo == 4
              "
            >
              <b-field label="Rodada Início">
                <b-numberinput 
                  v-model="form.rodada_inicio" 
                  controls-position="compact" 
                  min="1" max="38" 
                  required 
                  validation-message="Valor entre 1 e 38" 
                  :disabled="gamesFormAction == 'view'"
                ></b-numberinput>
              </b-field>
            </div>

            <div
              class="column"
              v-if="
                form.id_game_tipo == 1 ||
                form.id_game_tipo == 3 ||
                form.id_game_tipo == 4
              "
            >
              <b-field label="Rodada Final">
                <b-numberinput 
                  v-model="form.rodada_final" 
                  controls-position="compact" 
                  min="1" 
                  max="38" 
                  required 
                  validation-message="Valor entre 1 e 38" 
                  :disabled="gamesFormAction == 'view'"
                ></b-numberinput>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Posições">
                <template slot="label">
                  <b-checkbox v-model="checkPosition" @input="checkAllPositions()">Todas Posições</b-checkbox>
                </template>
                <v-select class="form-select multiple-select" multiple placeholder="Posições" :options="posicoesSiglas" v-model="form.posicoes" required :disabled="gamesFormAction == 'view'"></v-select>
              </b-field>
            </div>

            <div class="column is-6">
              <b-field label="Scouts" v-if="scoutsSiglas.length > 0 || form.scouts.length > 0">
                <template slot="label">
                  <b-checkbox 
                    v-model="checkScout" 
                    @input="checkAllScouts()"
                  >Todos Scouts</b-checkbox>
                </template>
                 <v-select class="form-select multiple-select" multiple placeholder="Scouts" :options="scoutsSiglas" v-model="form.scouts" required :disabled="gamesFormAction == 'view'"></v-select>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Apuração Scout">
                <b-select v-model="form.id_tipo_apuracao_scouts" required :disabled="gamesFormAction == 'view'" expanded>
                  <option
                    v-for="op of tipo_apuracao"
                    :key="op.id"
                    :value="op.id">
                    {{ op.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Tipo Rank Scout">
                <b-select v-model="form.id_tipo_rank_scouts" required :disabled="gamesFormAction == 'view'" expanded>
                  <option 
                    v-for="tipo of tipo_rank" 
                    :key="tipo.slug" 
                    :value="tipo.id"
                  >{{ tipo.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column is-6">
              <b-field label="Scouts Desempate" v-if="scoutsDesempateSiglas.length > 0 || form.scouts_desempate.length > 0">
                <template slot="label">
                  <b-checkbox v-model="checkScoutDesempate" @input="checkAllScoutsDesempate()">Todos Scouts Desempate</b-checkbox>
                </template>
                 <v-select class="form-select multiple-select" multiple placeholder="Scouts Desempate" :options="scoutsDesempateSiglas" v-model="form.scouts_desempate" required :disabled="gamesFormAction == 'view'"></v-select>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Apuração Desempate">
                <b-select v-model="form.id_tipo_apuracao_scouts_desempate" required :disabled="gamesFormAction == 'view'" expanded>
                  <option
                    v-for="op of tipo_apuracao"
                    :key="op.id"
                    :value="op.id"
                  >{{ op.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Tipo Rank Scout Desempate">
                <b-select v-model="form.id_tipo_rank_scouts_desempate" required :disabled="gamesFormAction == 'view'" expanded>
                  <option 
                    v-for="tipo of tipo_rank" 
                    :key="tipo.slug" 
                    :value="tipo.id"
                  >{{ tipo.descricao }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Atletas">
                <b-numberinput v-model="form.qtd_atletas" controls-position="compact" expanded min="1" max="12" required validation-message="Valor entre 1 e 12" :disabled="gamesFormAction == 'view'"></b-numberinput>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Atletas Reserva">
                <b-numberinput v-model="form.qtd_atletas_reserva" controls-position="compact" expanded min="0" max="5" required validation-message="Valor entre 0 e 12" :disabled="gamesFormAction == 'view'"></b-numberinput>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Cartoletas">
                <b-numberinput v-model="form.qtd_cartoletas" controls-position="compact" expanded min="1" required validation-message="Valor maior que 0" :disabled="gamesFormAction == 'view'"></b-numberinput>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field label="Cartoletas Reservas">
                <b-numberinput v-model="form.qtd_cartoletas_reserva" controls-position="compact" expanded min="0" required validation-message="Valor maior ou igual a 0" :disabled="gamesFormAction == 'view'"></b-numberinput>
              </b-field>
            </div>

            <div class="column is-full">
              <label>Descrição</label>
              <mc-wysiwyg v-model="form.descricao" :disabled="gamesFormAction == 'view'"></mc-wysiwyg>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item icon="door-open" icon-pack="fas">

          <template slot="header">
            <b-icon
              icon="door-open"
            ></b-icon>

            <span style="margin-right: 0.5em;">
              Salas
            </span>

            <b-button 
              v-if="gamesFormAction !== 'view'" 
              type="is-success" 
              size="is-small" 
              icon-pack="fas" 
              icon-left="plus" 
              @click="$refs.salaForm.addSala()"
              rounded
            ></b-button>

          </template>

          <div class="column is-full sala-bd">
            <Salas 
              ref="salaForm"
              :gamesFormAction="gamesFormAction" 
              :tipoGame="form.id_game_tipo"
              :influencer="selectedInfluencer"
              :salas="form.salas" 
              @change="(val) => handlerSala(val)"
            ></Salas>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>

    <div
      v-if="gamesFormLevel2"
      class="columns is-multiline notification has-background-bluedark2"
    >
      <div class="column is-full sala-bd">
        <SalasNv2
          :salasNv2="salasLevel2"
          v-on:select="gameLevel2 = $event"
        ></SalasNv2>
      </div>
    </div>
  </section>
</template>

<script>
import vSelect from 'vue-select'
import Salas from "./SalaForm";
import SalasNv2 from "./SalaNv2Form";
import Moment from 'moment'

import { mapActions, mapGetters } from "vuex";

export default {
  name: "games",
  components: {
    'v-select': vSelect,
    Salas,
    SalasNv2
  },
  data() {
    return {
      envMode: "",
      selectStatus: "aberta",
      games: [],
      gamesTable: false,
      gamesForm: false,
      gamesFormAction: "",
      gamesFormLevel2: false,
      gameLevel2: [],
      flagSelect: {},
      salas: [],
      salasLevel2: [],
      categorias: [],
      isLoading: false,
      checkPosition: false,
      checkScout: false,
      checkScoutDesempate: false,
      form: {
        ativo: true,
        id_game: 0,
        id_tipo_apuracao_scouts: 0,
        id_tipo_apuracao_scouts_desempate: 0,
        id_tipo_rank_scouts: 0,
        id_tipo_rank_scouts_desempate: 0,
        id_influencer: 0,
        scouts: [],
        scouts_desempate: [],
        posicoes: [],
        qtd_atletas: 0,
        qtd_atletas_reserva: 0,
        qtd_cartoletas: 0,
        qtd_cartoletas_reserva: 0,
        rodada_inicio: 0,
        rodada_final: 0,
        mes: null,
        descricao: "",
        salas: []
      },
      reseted_form: {
        ativo: true,
        id_game: 0,
        id_tipo_apuracao_scouts: 0,
        id_tipo_apuracao_scouts_desempate: 0,
        id_tipo_rank_scouts: 0,
        id_tipo_rank_scouts_desempate: 0,
        id_influencer: 0,
        scouts: [],
        scouts_desempate: [],
        posicoes: [],
        qtd_atletas: 0,
        qtd_atletas_reserva: 0,
        qtd_cartoletas: 0,
        qtd_cartoletas_reserva: 0,
        rodada_inicio: 0,
        rodada_final: 0,
        mes: null,
        descricao: "",
        salas: []
      },
      data: [],
      influencers: [],
      selectedInfluencer: {},
      rodadas: [],
      fechamento: [],
      tipo_game: [],
      tipo_apuracao: [],
      tipo_rank: [],
      mes: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      edit: {
        sala: null
      }
    };
  },
  computed: {
    ...mapGetters(["posicoes","scouts","userId", "userInfo", "mercado"]),
    opcCategorias() {

      let categorias = this.categorias
      let selected = this.edit.sala.categorias

      if (selected){
        categorias = categorias
        .filter(el => selected.findIndex(s => s.value == el.id) == -1)
      }

      return categorias
      .map(el =>  {
        return {
          label: el.descricao,
          value: el.id
        }
      })
    },
    posicoesSiglas() {

      this.checkPosition = (this.form.posicoes.length > 0 && this.form.posicoes.length == this.posicoes.length)

      let selected = this.form.posicoes

      return this.posicoes
      .filter(el => selected.indexOf(el.sigla) == -1 && el.sigla !== 'tec')
      .map(el => el.sigla)
    },
    posicoesScouts() {
      
      let scouts = this.scouts.map(s => s.cd_scout)
      scouts.sort()

      // let filPosition = this.posicoes.filter(
      //   p => this.form.posicoes.indexOf(p.sigla) !== -1
      // );
      
      // this.posicoes.map(p => {
      //   p.scouts.map(s => {
      //     let pScouts = s.scouts.split(',')
      //     for (const scout of pScouts) {
      //       if (scouts.indexOf(scout) == -1){
      //         scouts.push(scout)
      //       }
      //     }
      //   }) 
      // });

      // scouts.sort()

      return scouts
    },
    scoutsSiglas() {

      let scouts = this.posicoesScouts
      let selected = this.form.scouts

      this.checkScout = (this.form.scouts.length > 0 && this.form.scouts.length == scouts.length)

      return scouts
      .filter(scout => selected.indexOf(scout) == -1)
    },
    scoutsDesempateSiglas() {

      let scouts = this.posicoesScouts
      let selected = this.form.scouts_desempate

      this.checkScoutDesempate = (this.form.scouts_desempate.length > 0 && this.form.scouts_desempate.length == scouts.length)

      return scouts
      .filter(scout => selected.indexOf(scout) == -1)
    }
  },
  async created() {

    let loader = this.$loading.show()

    this.envMode = process.env.NODE_ENV;

    await this.getPosicoes()
    await this.getScouts()

    this.tipo_game = await this.getTypesGame();
    this.tipo_apuracao = await this.getTypesApuracao();
    this.tipo_rank = await this.getTypesGameRank()
    this.rodadas = await this.getRodadas();
    this.fechamento = await this.getFechamento();
    this.influencers = (await this.getInfluencers()).data;
    this.categorias = (await this.getCategorias()).data
    this.games = await this.getGamesByStatus({ status: this.selectStatus });

    for (const rodada of this.rodadas) {
      let month = Number(rodada.mes_rodada.substring(5,7))
      rodada.mes_extenso = this.mes[month-1]
    }

    this.gamesTable = true;
    this.gamesForm = false; 
    loader.hide()

  },
  methods: {
    ...mapActions([
      "getPosicoes",
      "getScouts",
      "getRodadas",
      "getFechamento",
      "getTypesGame",
      "getTypesApuracao",
      "getTypesGameRank",
      "getGamesByStatus",
      "getGameLevel2",
      "delGameSubSalas",
      "addNewGame",
      "flagGame",
      "sortGames",
      "destaqueGame",
      "destaqueGameInfluencer",
      "deleteGame",
      "getInfluencers",
      "setNextRoundTest",
      "getCategorias",
      "setRelSalaCategorias"
    ]),
    formatCategorias(categorias) {
      let strCategorias = ""
      if (categorias){
        categorias.map(el => strCategorias += (strCategorias !== '' ? ', ' : '') + el.descricao)
      }
      return strCategorias
    },
    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    async drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      let index = this.games.findIndex((i) => i.id == this.draggingRow.id);
      if (index !== -1) {
        this.gamesTable = false;
        this.isLoading = true;
        let oldOrder = this.draggingRowIndex + 1;
        let newOrder = droppedOnRowIndex + 1;
        this.games.map((l) => {
          if (newOrder > oldOrder) {
            l.ordem =
              l.ordem >= oldOrder && l.ordem <= newOrder
                ? l.ordem - 1
                : l.ordem;
          } else if (newOrder < oldOrder) {
            l.ordem =
              l.ordem >= newOrder && l.ordem <= oldOrder
                ? l.ordem + 1
                : l.ordem;
          }
        });
        this.games[index].ordem = newOrder;
        await this.sortGames({
          userId: this.userId,
          games: this.games.map((l) => {
            return { id: l.id, order: l.ordem };
          }),
        });
        this.$toasted.success(
          `Alterada a ordem do Game ${this.draggingRow.id} de ${oldOrder} para ${newOrder}`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
        this.isLoading = false;
        this.gamesTable = true;
      } else {
        this.$toasted.error(
          `Alteração da ordenação não realizada pois a ordem do game ${this.draggingRow.id} não foi localizada!`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      }
    },
    async filterStatus(status) {
      this.selectStatus = status;

      this.gamesTable = false;
      this.gamesForm = false;
      this.gamesFormLevel2 = false;

      let loader = this.$loading.show();

      this.games = await this.getGamesByStatus({ status: this.selectStatus });

      await this.reOrder();

      this.gamesTable = true;

      loader.hide();
    },
    async toggleFlag(game){

      this.flagSelect = game

      let active = game.ativo
      let title = (active) ? "Ativar Mini Game" : "Desativar Mini Game"
      let msg = (active) ? "O Mini Game será ativado e liberado para a visualização/inscrições dos usuários." : "O Mini Game será desativado e ficará oculto da lista para os usuários."
      let confirm = (active) ? "Ativar" : "Desativar"

      this.$buefy.dialog.confirm({
        title: title,
        message: '<p>Tem certeza que deseja alterar o flag de ativação?</p><p>'+msg+'</p>',
        confirmText: confirm,
        cancelText: 'Cancelar',
        type: (active) ? 'is-success' : 'is-danger',
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.ativo = !this.flagSelect.ativo
        }, 
        onConfirm: async () => {

          try {
            this.isLoading = true;

            let data = await this.flagGame({
              userId: this.userId,
              gameId: this.flagSelect.id
            });

            if (data.affectedRows == 1){

              this.$toasted.success("Flag alterado com sucesso!", {
                duration: 3000,
                position: "top-center"
              });

            }

            this.isLoading = false;

          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error("Ocorreu um problema ao tentar alterar o flag!", {
              duration: 3000,
              position: "top-center"
            });
          }

        }
      })

    },
    async toggleDestaque(game) {
      this.flagSelect = game;

      let destaque = game.destaque;
      let title = destaque
        ? "Ativar Destaque do Game"
        : "Desativar Destaque do Game";
      let msg = destaque
        ? "O game ficará em destaque na visualização/inscrições dos usuários."
        : "O destaque do game será desativada e será exibida na lista para os usuários de acordo com a ordenação definida.";
      let confirm = destaque ? "Ativar Destaque" : "Desativar Destaque";

      this.$buefy.dialog.confirm({
        title: title,
        message:
          "<p>Tem certeza que deseja alterar o flag do destaque do game?</p><p>" +
          msg +
          "</p>",
        confirmText: confirm,
        cancelText: "Cancelar",
        type: destaque ? "is-warning" : "is-danger",
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.destaque = !this.flagSelect.destaque;
        },
        onConfirm: async () => {
          try {
            this.isLoading = true;

            let data = await this.destaqueGame({
              userId: this.userId,
              id_game: this.flagSelect.id,
            });

            if (data.affectedRows == 1) {
              this.$toasted.success("Flag de destaque alterado com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }

            this.isLoading = false;
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error(
              "Ocorreu um problema ao tentar alterar o flag de destaque!",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        },
      });
    },
    async toggleDestaqueInfluencer(game) {
      
      this.flagSelect = game;

      let destaque_influencer = game.destaque_influencer;
      let title = (destaque_influencer
        ? "Ativar"
        : "Desativar") + " Destaque do Game na página do influenciador ";
      let msg = "Apenas na página do influenciador, " + (destaque_influencer
        ? "O game ficará em destaque na visualização/inscrições dos usuários."
        : "O destaque do game será desativada e será exibida na lista para os usuários de acordo com a ordenação definida.");
      let confirm = destaque_influencer ? "Ativar Destaque" : "Desativar Destaque";

      this.$buefy.dialog.confirm({
        title: title,
        message:
          "<p>Tem certeza que deseja alterar o flag do destaque do game?</p><p>" +
          msg +
          "</p>",
        confirmText: confirm,
        cancelText: "Cancelar",
        type: destaque_influencer ? "is-warning" : "is-danger",
        hasIcon: true,
        onCancel: async () => {
          this.flagSelect.destaque_influencer = !this.flagSelect.destaque_influencer;
        },
        onConfirm: async () => {
          try {
            this.isLoading = true;

            let data = await this.destaqueGameInfluencer({
              userId: this.userId,
              id_game: this.flagSelect.id,
            });

            if (data.affectedRows == 1) {
              this.$toasted.success("Flag de destaque alterado com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }

            this.isLoading = false;
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$toasted.error(
              "Ocorreu um problema ao tentar alterar o flag de destaque!",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        },
      });
    },
    checkAllPositions() {
      if (this.checkPosition){
        this.form.posicoes = this.posicoes.filter(el => el.sigla !== 'tec').map(el => el.sigla)
      } else {
        this.form.posicoes = []
      }
    },
    checkAllScouts() {
      if (this.checkScout){
        this.form.scouts = this.posicoesScouts
      } else {
        this.form.scouts = []
      }
    },
    checkAllScoutsDesempate() {
      if (this.checkScoutDesempate){
        this.form.scouts_desempate = this.posicoesScouts
      } else {
        this.form.scouts_desempate = []
      }
    },
    async addGame() {
      this.gamesTable = false
      this.gamesForm = true
      this.gamesFormAction = "add"
    },
    async copyGame(game) {

      await this.fillForm(game)

      //excluído os ids para que o form identifique como uma inclusão de uma nova game, apesar de ter sido feito uma cópia de uma game já existente
      delete this.form.id_game;
      this.form.salas.map((s) => {
        delete s.id_sala;
        s.subSalas.map((sS) => {
          delete sS.id_sub_sala;
        });
      });

      this.gamesTable = false
      this.gamesForm = true
      this.gamesFormAction = "copy"
    },
    async resetForm(){
      
      let loader = this.$loading.show()
      
      this.gamesForm = false; 
      this.gamesFormLevel2 = false;
      this.gamesTable = false;

      this.form = JSON.parse(JSON.stringify(this.reseted_form));
      this.influencers = (await this.getInfluencers()).data;
      this.games = await this.getGamesByStatus({ status: this.selectStatus });

      await this.reOrder();

      this.gamesTable = true;
      loader.hide();
    },
    async reOrder() {
      let qtdGames = this.games.filter((l) => l.ordem > 0).length;

      if (this.games.length > 0) {
        let newGames = this.games
          .filter((l) => !l.ordem || l.ordem == 0)
          .map((l) => {
            l.ordem = ++qtdGames;
            return { id: l.id, order: l.ordem };
          });

        if (newGames.length > 0) {
          let response = await this.sortGames({
            userId: this.userId,
            games: newGames,
          });

          let result = true;
          response.map((r) => {
            if (r.affectedRows === 0) {
              result = false;
            }
          });

          if (result) {
            this.$toasted.success(`Ordenação realizada para novos games!`, {
              duration: 3000,
              position: "top-center",
            });
          } else {
            this.$toasted.error(
              `Ocorreu um problema na ordenação das novos games!`,
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        }
      }
    },
    async saveGame() {

      if (await this.validGame()){
        
        try {
          this.isLoading = true;

          let params = await this.prepareParams()

          for (const param of params) {
            let data = await this.addNewGame({
              userId: this.userId,
              form: param,
            });

            if (data.error) {
              let acao =
                "add,copy".indexOf(this.gamesFormAction) !== -1
                  ? "Inclusão"
                  : "Edição";
              this.$toasted.error(
                acao + " do Mini Game não permitida! " + data.error,
                {
                  duration: 3000,
                  position: "top-center",
                }
              );
            } else {
              let acao =
                "add,copy".indexOf(this.gamesFormAction) !== -1
                  ? "incluído"
                  : "alterado";
              this.$toasted.success("Mini Game " + acao + " com sucesso!", {
                duration: 3000,
                position: "top-center",
              });
            }
          }
          this.resetForm();
          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log(err);
          this.$toasted.error(
            "Ocorreu um problema ao tentar registrar o Mini Game!",
            {
              duration: 3000,
              position: "top-center",
            }
          );
        }
      }
    },
    async validGame() {

      let valid = await this.validRodada()
      
      if (valid) {

        let msgError = ""

        if (!this.form.id_influencer || this.form.id_influencer == "") {
          msgError += "<p>* Influenciador não selecionado</p>";
        }
        if (!this.form.id_game_tipo || this.form.id_game_tipo == "") {
          msgError += "<p>* Tipo de Game não selecionado</p>";
        }
        if (!this.form.id_tipo_apuracao_scouts || this.form.id_tipo_apuracao_scouts == "") {
          msgError += "<p>* Tipo de Apuração para Scouts não selecionado</p>";
        }
        if (!this.form.id_tipo_apuracao_scouts_desempate || this.form.id_tipo_apuracao_scouts_desempate == "") {
          msgError += "<p>* Tipo de Apuração para Desempate não selecionado</p>";
        }
        if (!this.form.id_tipo_rank_scouts || this.form.id_tipo_rank_scouts == "") {
          msgError += "<p>* Tipo de Apuração para Scouts não selecionado</p>";
        }
        if (!this.form.id_tipo_rank_scouts_desempate || this.form.id_tipo_rank_scouts_desempate == "") {
          msgError += "<p>* Tipo de Apuração para Desempate não selecionado</p>";
        }
        if (this.form.qtd_atletas < 1 || this.form.qtd_atletas > 12){
          msgError += "<p>* Quantidade de atletas deverá ser um valor entre 1 e 12</p>"
        }
        if (this.form.qtd_atletas_reserva < 0 || this.form.qtd_atletas_reserva > 5){
          msgError += "<p>* Quantidade de atletas reserva deverá ser um valor entre 0 e 5</p>"
        }

        if (this.form.salas.length == 0){
          msgError += "<p>* Não existem salas definidas para o Mini Game</p>"
        } else {
          let index = 0;
          let comReserva = false;
          let semReserva = false;
          for (const sala of this.form.salas) {

            if (!comReserva){
              comReserva = sala.limite_reserva && sala.limite_reserva !== "" && sala.limite_reserva !== null
            }
            if (!semReserva){
              semReserva = !sala.limite_reserva || sala.limite_reserva == "" || sala.limite_reserva == null
            }

            index++;
            if (sala.valor === null || sala.valor === "") {
              msgError +=
                "<p>* Valor de inscrição não definido na sala " +
                index +
                ". Caso seja uma sala gratuita, favor informar o valor 0 (zero)</p>";
            }
            if (sala.percent_escala === null || sala.percent_escala === "") {
              msgError +=
                "<p>* % Escala10 não definido na sala " +
                index +
                ". Caso não possua um % para o Escala10, favor informar o valor 0 (zero)</p>";
            }
            if (sala.percent_sala === null || sala.percent_sala === "") {
              msgError +=
                "<p>* % Influenciador não definido na sala " +
                index +
                ". Caso não possua um % para o Influenciador, favor informar o valor 0 (zero)</p>";
            }
            if (!sala.premiacao || sala.premiacao.length == 0) {
              msgError +=
                "<p>* Não existem premiações definidas para a sala " +
                index +
                "</p>";
            } else {
              
              sala.percent_escala = sala.percent_escala ? Number(sala.percent_escala) : 0;
              sala.percent_sala = sala.percent_sala ? Number(sala.percent_sala) : 0;
              sala.prev_qtd_time = sala.prev_qtd_time ? Number(sala.prev_qtd_time) : 0

              let percentTotal = 100 - sala.percent_escala - sala.percent_sala;
              let percent = Number(percentTotal.toFixed(4));
              let maiorLimiar = 0;
              for (const premiacao of sala.premiacao) {

                premiacao.limiar = premiacao.limiar ? Number(premiacao.limiar) : 0
  
                let tipos = [];
                let percentSub = percent;
                if (premiacao.limiar <= 0) {
                  msgError +=
                    "<p>* sala " +
                    index +
                    " deverá possuir um limiar maior do que 0 (zero)</p>";
                } else if (
                  !premiacao.subsalas ||
                  premiacao.subsalas.length == 0
                ) {
                  msgError +=
                    "<p>* Não existem subsalas definidas para a sala " +
                    index +
                    " com limiar " +
                    premiacao.limiar.toString() +
                    "</p>";
                } else {
                  maiorLimiar =
                    premiacao.limiar > maiorLimiar
                      ? premiacao.limiar
                      : maiorLimiar;
                  for (const subsala of premiacao.subsalas) {
                    subsala.percent_sub = subsala.percent_sub
                      ? Number(Number(subsala.percent_sub).toFixed(4))
                      : 0;
                    percentSub -= subsala.percent_sub;
                    percentSub = Number(percentSub.toFixed(4));
                    let percentRnk = 100;
                    if (
                      !subsala.tipo ||
                      subsala.tipo == 0 ||
                      subsala.tipo == ""
                    ) {
                      msgError +=
                        "<p>* Tipo de premiação não definida na sala " +
                        index +
                        " com limiar " +
                        premiacao.limiar.toString() +
                        " com % " +
                        subsala.percent_sub +
                        "</p>";
                    } else if (
                      !subsala.ranking ||
                      subsala.ranking.length == 0
                    ) {
                      msgError +=
                        "<p>* Não existem rankings definidos para a sala " +
                        index +
                        " com limiar " +
                        premiacao.limiar.toString() +
                        " na premiação " +
                        subsala.tipo_name.toUpperCase() +
                        "</p>";
                    } else {
                      for (const ranking of subsala.ranking) {
                        ranking.percent_posicao = ranking.percent_posicao
                          ? Number(Number(ranking.percent_posicao).toFixed(4))
                          : 0;
                        percentRnk -= ranking.percent_posicao;
                        percentRnk = Number(percentRnk.toFixed(4));
                      }
                      if (percentRnk > 100) {
                        msgError +=
                          "<p>* A soma dos percentuais do ranking deverão totalizar 100%</p><p>* Premiação " +
                          subsala.tipo_name.toUpperCase() +
                          " da sala " +
                          index +
                          " com " +
                          Math.abs(percentRnk).toString() +
                          "% excedido</p>";
                      }
                      if (tipos.indexOf(subsala.tipo) !== -1) {
                        msgError +=
                          "<p>* Premiação do tipo " +
                          subsala.tipo_name.toUpperCase() +
                          " em duplicidade na sala " +
                          index +
                          " com limmiar " +
                          premiacao.limiar.toString() +
                          "</p>";
                      } else {
                        tipos.push(subsala.tipo);
                      }
                    }
                  }
                  if (percentSub !== 0) {
                    // msgError += "<p>* Existe um saldo de "+percent+"% a ser distribuído na sala "+index+" com limmiar "+premiacao.limiar.toString()+"</p><p>A soma dos percentuais dessa sala deverão totalizar "+percentTotal+"%</p>"
                    msgError +=
                      "<p>* A soma dos percentuais de prêmios da sala" +
                      index +
                      " com limmiar " +
                      premiacao.limiar.toString() +
                      " deverão totalizar " +
                      percentTotal +
                      "%</p><p>* Total de prêmios da sala " +
                      index +
                      " com limmiar " +
                      premiacao.limiar.toString() +
                      ": " +
                      percentSub +
                      "%</p>";
                  }
                }
              }
              if (sala.prev_qtd_time > 0 && sala.prev_qtd_time < maiorLimiar) {
                msgError +=
                  "<p>* sala " +
                  index +
                  " com quantidade prevista menor do que o maior limiar</p>";
              }
            }
          }

          if (comReserva && semReserva){
            msgError +=
              "<p>* Todas as salas deverão permitir a reserva, caso alguma permita. Preencha ou retire a data limite para reserva em todas as salas.</p>";
          }
        }

        if (msgError !== "") {
          valid = false;
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: msgError,
            type: "is-danger",
            hasIcon: true,
          });
        }
      }

      return valid
    },
    async prepareParams() {

      let params = JSON.parse(JSON.stringify(this.form))

      let reserva = false
      params.salas = params.salas.map(sala => {
        sala.limite_reserva = (sala.limite_reserva) ? Moment(sala.limite_reserva).format('YYYY-MM-DD HH:mm:ss') : null
        reserva = sala.limite_reserva && sala.limite_reserva !== "" && sala.limite_reserva !== null
        return sala
      })
      params.reserva = reserva

      params.scouts = params.scouts.join(',')
      params.scouts_desempate = params.scouts_desempate.join(',')
      params.posicoes = params.posicoes.join(',')

      for (const sala of params.salas) {
        let premios = [];
        for (const premiacao of sala.premiacao) {
          for (const subsala of premiacao.subsalas) {
            premios.push(subsala);
          }
        }
        sala.premiacao = premios;
      }

      //Tiro Curto - Game em Lote por Rodada
      if (
        params.id_game_tipo == 1 &&
        params.rodada_inicio !== params.rodada_final
      ) {
        let rodadaInicial = params.rodada_inicio;
        let rodadaFinal = params.rodada_final;
        params.rodada_final = params.rodada_inicio;
        let loteParams = [JSON.parse(JSON.stringify(params))];

        for (let rodada = rodadaInicial + 1; rodada <= rodadaFinal; rodada++) {
          params.rodada_inicio = rodada;
          params.rodada_final = rodada;
          loteParams.push(JSON.parse(JSON.stringify(params)));
        }

        params = loteParams;
      } else if (params.id_game_tipo == 2 && params.mes !== params.mes_final) {
        let mesInicial = params.mes;
        let mesFinal = params.mes_final;
        params.mes_final = params.mes;
        let loteParams = [JSON.parse(JSON.stringify(params))];

        for (const rodada of this.rodadas) {
          if (rodada.mes_rodada > mesInicial && rodada.mes_rodada <= mesFinal) {
            params.mes = rodada.mes_rodada;
            params.mes_final = rodada.mes_rodada;
            params.rodada_inicio = rodada.rodada_ini;
            params.rodada_final = rodada.rodada_fim;
            loteParams.push(JSON.parse(JSON.stringify(params)));
          }
        }

        params = loteParams;
      } else {
        params = [params];
      }

      return params
    },
    async viewGame(game) {

      await this.fillForm(game)

      this.gamesTable = false
      this.gamesForm = true
      this.gamesFormAction = "view"
    },
    async editGame(game) {

      await this.fillForm(game)

      this.gamesTable = false
      this.gamesForm = true
      this.gamesFormAction = "edit"
    },
    async delGame(game) {

      if (game){

        await this.fillForm(game)
        this.gamesFormAction = "delete"

        this.$buefy.dialog.confirm({
          title: 'Exclusão do Mini Game',
          message: 'Tem certeza que deseja excluir este Mini Game?',
          confirmText: 'Excluir',
          cancelText: 'Cancelar',
          type: 'is-danger',
          hasIcon: true,
            onConfirm: async () => {

            try {
              this.isLoading = true;

              let data = await this.deleteGame({
                userId: this.userId,
                gameId: this.form.id_game
              });

              if (data[0][0].flag == 1){

                this.$toasted.success("Mini Game excluído com sucesso!", {
                  duration: 3000,
                  position: "top-center"
                });

                this.resetForm()

              } else {
                this.$toasted.error("<p>Exclusão do Mini Game não permitida!</p><p>"+data[0][0].msg+"</p>", {
                  duration: 3000,
                  position: "top-center"
                });
              }

              this.isLoading = false;

            } catch (err) {
              this.isLoading = false;
              console.log(err);
              this.$toasted.error("Ocorreu um problema ao tentar excluir o Mini Game!", {
                duration: 3000,
                position: "top-center"
              });
            }

          }
        })
      }
    },
    async delGameLevel2(game) {
      this.salasLevel2 = await this.getGameLevel2({
        id_game: game.id,
      });

      this.gamesTable = false;
      this.gamesFormLevel2 = true;
      this.gamesFormAction = "redo";
    },
    async redoGame() {

      if (this.gameLevel2.length == 0) {
        this.$toasted.error("Nenhum registro selecionado!", {
          duration: 3000,
          position: "top-center",
        });
        return;
      }

      try {
        let res = await this.delGameSubSalas({
          subSalas: this.gameLevel2,
        });

        if (res.error) {
          this.$toasted.error(res.error, {
            duration: 3000,
            position: "top-center",
          });
        } else {
          if (res.result) {
            let del = { ok: 0, erro: 0 };
            let msgErro = "";

            for (const result of res.result) {
              if (result.cod == 1) {
                del.ok++;
              } else if (result.cod == 0) {
                del.erro++;

                if (msgErro.indexOf(result.msg) == -1) {
                  msgErro += (msgErro !== "" ? "; " : "") + result.msg;
                }
              }
            }

            if (del.erro > 0) {
              this.$buefy.dialog.alert({
                title: "ATENÇÃO!",
                message: `<p>Ocorreu um problema na exclusão de ${del.erro} subsala(s).</p><p>${msgErro}</p>`,
                type: "is-danger",
                hasIcon: true,
              });
            }

            if (del.ok > 0) {
              this.$toasted.success(
                `${del.ok} SubSala(s) excluída(s) com sucesso!`,
                {
                  duration: 5000,
                  position: "top-center",
                }
              );
              this.resetForm();
            }

          } else {
            this.$toasted.error(
              "Ocorreu um problema na exclusão das SubSalas.",
              {
                duration: 3000,
                position: "top-center",
              }
            );
          }
        }
      } catch (err) {
        this.$toasted.error("Não foi possível salvar", {
          duration: 3000,
          position: "top-center",
        });
      }
    },
    async fillForm(game) {

      let formGame = await this.getGamesByStatus({
        status: this.selectStatus,
        id_game: game.id,
      });

      this.form.ativo = formGame.ativo
      this.form.id_game = formGame.id
      this.form.id_game_tipo = formGame.game_tipo.id;
      this.form.id_tipo_apuracao_scouts = formGame.apuracao_tipo_scouts.id
      this.form.id_tipo_apuracao_scouts_desempate = formGame.apuracao_tipo_scouts_desempate.id
      this.form.id_tipo_rank_scouts = formGame.rank_tipo_scouts.id
      this.form.id_tipo_rank_scouts_desempate = formGame.rank_tipo_scouts_desempate.id
      this.form.id_influencer = formGame.influencer.id
      this.form.scouts = (formGame.scouts) ? formGame.scouts.split(',') : []
      this.form.scouts_desempate = (formGame.scouts_desempate) ? formGame.scouts_desempate.split(',') : []
      this.form.posicoes = (formGame.posicoes) ? formGame.posicoes.split(',') : []
      this.form.qtd_atletas = formGame.qtd_atletas
      this.form.qtd_atletas_reserva = formGame.qtd_atletas_reserva
      this.form.qtd_cartoletas = formGame.qtd_cartoletas
      this.form.qtd_cartoletas_reserva = formGame.qtd_cartoletas_reserva
      this.form.rodada_inicio = formGame.rodada_inicio
      this.form.rodada_final = formGame.rodada_final
      this.form.mes = formGame.mes
      this.form.descricao = formGame.descricao

      this.setInfluencer()

      this.form.salas = formGame.salas.map(sala => {
        sala.limite_reserva = (sala.limite_reserva) ? new Date(sala.limite_reserva) : null
        return sala
      });

    },
    async setInfluencer() {
      
      let influencer = this.influencers.find(i => i.id == this.form.id_influencer)
      if (influencer){
        
        this.selectedInfluencer = influencer
        
        if (influencer.percent_escala){
          this.form.salas.map(s => {
            s.percent_escala = influencer.percent_escala
          })
        }
        if (influencer.percent_sala){
          this.form.salas.map(s => {
            s.percent_sala = influencer.percent_sala
          })
        }
      }
    },
    async changeType() {
      this.form.mes = null;
      this.form.rodada_inicio = 0;
      this.form.rodada_final = 0;

      if (this.form.id_game_tipo == 4) {
        if (this.rodadas) {
          this.form.rodada_inicio = this.rodadas[0].rodada_ini;
          this.form.rodada_final = this.rodadas[
            this.rodadas.length - 1
          ].rodada_fim;
        }
      }
    },
    async setRodadas(mes) {
      if (this.rodadas && this.rodadas.length > 0) {
        for (const rodada of this.rodadas) {
          if (rodada.mes_rodada == mes) {
            this.form.rodada_inicio = rodada.rodada_ini;
            this.form.rodada_final = rodada.rodada_fim;
          }
        }
      }
    },
    handlerSala(salas) {
      this.form.salas = salas;
    },
    async validRodada() {

      let valid = true
      
      if (this.form.rodada_inicio > 0 && this.form.rodada_final > 0){
        if (this.form.rodada_inicio < this.mercado.rodada_atual){
          valid = false
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Rodada inicial deverá ser maior ou igual a rodada atual "+this.mercado.rodada_atual,
            type: "is-danger",
            hasIcon: true
          });
        }
        if (this.form.rodada_final < this.form.rodada_inicio){
          valid = false
          this.$buefy.dialog.alert({
            title: "ATENÇÃO!",
            message: "Rodada final deverá ser maior ou igual a rodada inicial",
            type: "is-danger",
            hasIcon: true
          });
          this.form.rodada_final = this.form.rodada_inicio
        }
      }
      if (this.form.rodada_final > 38){
        valid = false
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "Rodada final deverá ser menor ou igual a 38",
          type: "is-danger",
          hasIcon: true
        });
        this.form.rodada_final = 38
      } 
      
      if ('add,copy'.indexOf(this.gamesFormAction) !== -1){
        
        this.form.rodada_inicio = Number(this.form.rodada_inicio)
        this.form.rodada_final = Number(this.form.rodada_final)

        if (this.form.rodada_inicio > 0 && this.form.rodada_final > 0){
        
          let dataAtual = new Date()
          let anoAtual = dataAtual.getFullYear()
          let rodadas = ""
          dataAtual = Moment(dataAtual)

          for (
            let rodada = this.form.rodada_inicio; 
            rodada <= this.form.rodada_final; 
            rodada++
          ) {
            for (const fechaRodada of this.fechamento) {
              if (fechaRodada.id_rodada == rodada){

                let dataFechamento = new Date(fechaRodada.fechamento_mercado)
                let anoRodada = dataFechamento.getFullYear()
                dataFechamento = Moment(dataFechamento)

                if (anoRodada == anoAtual && dataFechamento <= dataAtual){
                  rodadas += 
                    (rodadas !== '' ? ',' : '') + 
                    fechaRodada.id_rodada.toString()
                }
              }
            }
          }
          if (rodadas !== ''){
            valid = false
            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "Rodadas encerradas para inscrição: "+rodadas,
              type: "is-danger",
              hasIcon: true
            });
          }
        }
      }

      return valid
    },
    async nextRound() {
      let res
      try {
        res = await this.setNextRoundTest();
        this.$buefy.dialog.alert({
          title: "Rodada",
          message: res.msg,
          type: "is-success",
          hasIcon: true,
          icon: "check",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true
        });
      } catch (err) {
        console.log(err);
        this.$buefy.dialog.alert({
          title: "Rodada",
          message: res.msg,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true
        });
      }
    },
    formatDate(data){
      let newDate = ""
      if (data && data !== '0000-00-00 00:00:00'){
        Moment.locale('pt-br')
        newDate = Moment(new Date(data))
        newDate = newDate.format('ddd, DD/MMM, HH:mm:ss')
      }
      return newDate
    },
    editSalaCategorias(sala){
      this.edit.sala = JSON.parse(JSON.stringify(sala))
      if (this.edit.sala.categorias.length){
        this.edit.sala.categorias = this.edit.sala.categorias.map(c => {
          return {
            label: c.descricao,
            value: c.id
          }
        })
      }
    },
    cancelSalaCategorias(){
      this.edit.sala = null
    },
    async saveSalaCategorias(sala){

      let loader = this.$loading.show();
      try {
        let res = await this.setRelSalaCategorias({
          userId: this.userId,
          gameId: this.edit.sala.id_game,
          salaId: this.edit.sala.id_sala,
          categorias: this.edit.sala.categorias
        });

        if (!res.error){
          sala.categorias = this.edit.sala.categorias.map(c => {
            return {
              id: c.value,
              descricao: c.label
            }
          })
          this.edit.sala = null
        } else {
          this.$buefy.dialog.alert({
            title: "Categorias",
            message: res.msg,
            type: "is-danger",
            hasIcon: true,
            icon: "times-circle",
            iconPack: "fa",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        }
      } catch (err) {
        console.log(err);
        this.$buefy.dialog.alert({
          title: "Categorias",
          message: err.msg,
          type: "is-danger",
          hasIcon: true,
          icon: "times-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      }

      loader.hide();
    }
  }
};
</script>

<style scoped>
.toolbar {
  text-align: right;
  padding: 1.5em;
}
</style>
