<template>
  <section id="SalaForm" style="padding: 0 !important;">
    <div v-if="renderTable && form" class="has-background-bluedark">

      <b-tabs 
        v-if="form.length"
        v-model="activeTab.sala" 
        type="is-boxed"
      >
        <b-tab-item 
          v-for="(sala, index) of form"
          :key="`sala_${index}`"
        >
          <template 
            slot="header"
            :id="`sala_${index}_header`"
          >
            <b-tooltip 
              :label="'Excluir Sala '+(index+1)"
              type="is-danger" 
              position="is-right" 
              animated
            >
              <span @click="delSala(form, index)">
                <b-icon
                  v-if="gamesFormAction !== 'view'" 
                  type="is-danger"
                  pack="fas"
                  icon="times-circle"
                  style="padding: 0; margin: 0; marging-right: 2em;"
                ></b-icon>
              </span>
            </b-tooltip>
            <span>
              {{ 'Sala '+(index+1) }}
            </span>
          </template>

          <div 
            class="columns is-multiline" 
          >

            <div class="column is-full">

              <b-tabs 
                v-model="activeTab.limiar" 
                size="is-small"
                type="is-boxed"
              >
                <b-tab-item 
                  :key="`sala_${index}_principal`"
                >
                  <template 
                    slot="header"
                    :id="`sala_${index}_principal_header`"
                  >
                    <b-button 
                      v-if="gamesFormAction !== 'view'" 
                      label="Limiar"
                      type="is-success" 
                      size="is-small" 
                      icon-pack="fas" 
                      icon-left="plus" 
                      @click="addLimiar(sala)"
                      rounded
                    ></b-button>

                    <span style="margin-left: 0.5em;">
                      Dados Principais
                    </span>

                  </template>

                  <div 
                    class="columns is-multiline" 
                    :style="{'padding-top': '0', 'padding-bottom': '0'}"
                  >
                    <div class="column">
                      <b-field label="Valor da Inscrição" label-position="on-border" type="is-primary">
                        <b-input type="number" step="0.0001" v-model="sala.valor" :disabled="gamesFormAction == 'view'" required></b-input>
                      </b-field>

                      <b-field label="% Influenciador" label-position="on-border" type="is-primary">
                        <b-input type="number" step="0.0001" v-model="sala.percent_sala" @input="calcPremio(sala)" :disabled="gamesFormAction == 'view' || !userInfo.isAdmin" required></b-input>
                      </b-field>

                      <b-field label="% Escala10" label-position="on-border" type="is-primary">
                        <b-input type="number" step="0.0001" v-model="sala.percent_escala" @input="calcPremio(sala)" :disabled="gamesFormAction == 'view' || !userInfo.isAdmin" required></b-input>
                      </b-field>

                      <b-field label="Título" label-position="on-border">
                        <b-input type="text" v-model="sala.titulo" :disabled="gamesFormAction == 'view'"></b-input>
                      </b-field>
                    </div>

                    <div class="column">
                      <b-field label="Qtd.Prevista" label-position="on-border">
                        <b-input type="number" v-model="sala.prev_qtd_time" :disabled="gamesFormAction == 'view'"></b-input>
                      </b-field>

                      <b-field label="Limite de Inscrições" label-position="on-border">
                        <b-input type="number" v-model="sala.max_qtd_time" :disabled="gamesFormAction == 'view'"></b-input>
                      </b-field>

                      <b-field label="Limite de Inscrições por Usuário" label-position="on-border">
                        <b-input type="number" v-model="sala.max_qtd_time_pssa" :disabled="gamesFormAction == 'view'"></b-input>
                      </b-field>

                      <b-field label="Limite para Reserva" label-position="on-border">
                        <b-datetimepicker v-model="sala.limite_reserva" icon="calendar-today" editable trap-focus :disabled="gamesFormAction == 'view'"></b-datetimepicker>
                      </b-field>
                    </div>

                    <div class="column">

                      <b-field label="Categorias" label-position="on-border">
                        <v-select class="form-select multiple-select" multiple placeholder="Categorias" :options="opcCategorias" v-model="sala.categorias" required :disabled=" gamesFormAction == 'view'"></v-select>
                      </b-field>

                      <b-field label="Senha" label-position="on-border">
                        <b-input type="text" v-model="sala.senha" :disabled="gamesFormAction == 'view'"></b-input>
                      </b-field>

                      <div class="columns">
                        <div class="column is-half">
                          <b-field label="Com Capitão?">
                            <b-checkbox 
                              v-model="sala.capitao" 
                              :disabled="gamesFormAction == 'view'"
                              true-value="1"
                              false-value="0">
                            </b-checkbox>
                          </b-field>
                        </div>
                        <div class="column is-half">
                          <b-field label="Clonagem?">
                            <b-checkbox 
                              v-model="sala.clonagem" 
                              :disabled="gamesFormAction == 'view'"
                              true-value="1"
                              false-value="0">
                            </b-checkbox>
                          </b-field>
                        </div>
                      </div>
                    </div>

                    <div class="column is-full">
                      <b-field label="Descrição">
                        <mc-wysiwyg v-model="sala.descricao" height="150" :disabled="gamesFormAction == 'view'"></mc-wysiwyg>
                      </b-field>
                    </div>
                  </div>

                </b-tab-item>

                <b-tab-item 
                  v-for="(limiar, index2) of sala.premiacao"
                  :key="`sala_${index}_limiar_${index2}`"
                >
                  <template 
                    slot="header"
                    :id="`sala_${index}_limiar_${index2}_header`"
                  >
                    <b-tooltip 
                      :label="'Excluir Limiar '+(index2+1)"
                      type="is-danger" 
                      position="is-right" 
                      animated
                    >
                      <span @click="delLimiar(sala.premiacao, index2)">
                        <b-icon
                          v-if="gamesFormAction !== 'view'" 
                          type="is-danger"
                          pack="fas"
                          icon="times-circle"
                          style="padding: 0; margin: 0; marging-right: 2em;"
                        ></b-icon>
                      </span>
                    </b-tooltip>
                    <span>
                      {{ 'Limiar '+(index2+1) }}
                    </span>
                  </template>

                  <div
                    class="columns is-multiline"
                  >
                    <div class="column is-narrow">
                      <div v-if="gamesFormAction !== 'view'">
                        <b-field label="nº Premiações" label-position="on-border">
                          <b-input v-model="limiar.isubsalas" ></b-input>
                          <p class="control">
                            <b-button type="is-purple" icon-pack="fas" icon-right="arrow-alt-circle-down" @click="addSubSala(sala, limiar)"/>
                          </p>
                        </b-field>
                      </div>
                      <div v-else class="has-background-purple" style="text-align: center;">
                        <div>{{limiar.isubsalas}} {{(limiar.isubsalas > 1) ? "Premiações" : "Premiação"}}</div>
                      </div>
                    </div>

                    <div class="column is-narrow">
                      <b-field label="Min.Qtd.Time" label-position="on-border">
                        <b-input 
                          type="number" 
                          v-model="limiar.limiar" 
                          step="1" 
                          min="0" 
                          required 
                          validation-message="Valor deverá ser maior do que 0" 
                          @input="updateLimiar(limiar)" 
                          :disabled="gamesFormAction == 'view'"
                        ></b-input>
                      </b-field>
                    </div>

                    <div class="column is-full has-background-bluedark2" style="padding: 0 !important;">

                      <b-tabs 
                        v-model="activeTab.premio" 
                        size="is-small"
                        type="is-boxed"
                      >
                        <b-tab-item
                          v-for="(premio, index3) of limiar.subsalas"
                          :key="`sala_${index}_limiar_${index2}_premio_${index3}`"
                        >
                          <template 
                            slot="header"
                            :id="`sala_${index}_limiar_${index2}_premio_${index3}_header`"
                          >
                            <b-tooltip 
                              :label="'Excluir Premiação '+(index3+1)"
                              type="is-danger" 
                              position="is-right" 
                              animated
                            >
                              <span @click="delSubSala(limiar, limiar.subsalas, index3)">
                                <b-icon
                                  v-if="gamesFormAction !== 'view' && (index3 + 1) == limiar.subsalas.length" 
                                  type="is-danger"
                                  pack="fas"
                                  icon="times-circle"
                                  style="padding: 0; margin: 0; marging-right: 2em;"
                                ></b-icon>
                              </span>
                            </b-tooltip>
                            <span>
                              {{ 'Premiação ' + (index3 + 1) }}
                            </span>
                          </template>


                          <div class="columns is-multiline">
                            <div class="column is-narrow">
                              <div v-if="gamesFormAction !== 'view'">
                                <b-field label="nº Rankings" label-position="on-border">
                                  <b-input size="is-small" v-model="premio.irankings" ></b-input>
                                  <p class="control">
                                    <b-button size="is-small" type="is-info" icon-pack="fas" icon-right="arrow-alt-circle-down" @click="addRanking(premio)"/>
                                  </p>
                                </b-field>
                              </div>
                              <div v-else class="has-background-purple" style="text-align: center;">
                                <div>{{premio.irankings}} {{(premio.irankings > 1) ? "Rankings" : "Ranking"}}</div>
                              </div>
                            </div>

                            <div class="column is-narrow">
                              <b-field label="Tipo" label-position="on-border">
                                <b-select size="is-small" v-model="premio.tipo" @input="validType(premio)" :disabled="gamesFormAction == 'view'">
                                  <option v-for="tipo of tipo_premio" :key="tipo.slug" :value="tipo.id">{{ tipo.descricao }}</option>
                                </b-select>
                              </b-field>
                            </div>

                            <div class="column is-narrow">
                              <b-field label="% Prêmio" label-position="on-border">
                                <b-input type="number" step="0.0001" size="is-small" v-model="premio.percent_sub" @input="updatePremio(sala, limiar, premio)" :disabled="gamesFormAction == 'view'"></b-input>
                              </b-field>
                            </div>

                            <div class="column is-full">

                              <b-table
                                blueDark
                                :data="premio.ranking"
                                ref="tableRanking"
                                :default-sort="['posicao', 'asc']"
                                :mobile-cards="true"
                              >

                                <template slot-scope="rnk">

                                  <b-table-column centered>
                                    <b-tooltip label="Excluir Ranking" type="is-danger" position="is-right" animated>
                                      <b-button v-if="gamesFormAction !== 'view' && rnk.index == (premio.ranking.length-1)" type="is-danger" icon-pack="fas" size="is-small" icon-right="times" @click="delRanking(premio, premio.ranking, rnk.index)"/>
                                    </b-tooltip>
                                  </b-table-column>

                                  <b-table-column field="posicao" label="Posição" centered>
                                    <b-field>
                                      <b-input size="is-small" v-model="rnk.row.posicao" disabled></b-input>
                                    </b-field>
                                  </b-table-column>

                                  <b-table-column field="percent_posicao" label="%" centered>
                                    <b-field>
                                      <b-input type="number" step="0.0001" size="is-small" v-model="rnk.row.percent_posicao" :disabled="gamesFormAction == 'view'"></b-input>
                                    </b-field>
                                  </b-table-column>

                                  <b-table-column field="premio_fixo" label="Vl.Fixo" centered>
                                    <b-field>
                                      <b-input type="number" step="0.0001" size="is-small" v-model="rnk.row.premio_fixo" :disabled="gamesFormAction == 'view'"></b-input>
                                    </b-field>
                                  </b-table-column>

                                  <b-table-column field="premio_fixo_bonus" label="Vl.Fixo Bônus" centered>
                                    <b-field>
                                      <b-input type="number" step="0.0001" size="is-small" v-model="rnk.row.premio_fixo_bonus" :disabled="gamesFormAction == 'view'"></b-input>
                                    </b-field>
                                  </b-table-column>

                                  <b-table-column field="premio_produto" label="Produto" centered>
                                    <b-field>
                                      <b-input type="text" size="is-small" v-model="rnk.row.premio_produto" :disabled="gamesFormAction == 'view'"></b-input>
                                    </b-field>
                                  </b-table-column>

                                </template>
                              </b-table>

                            </div>
                          </div>
                        </b-tab-item>
                      </b-tabs>
                    </div>
                  </div>
                </b-tab-item>

              </b-tabs>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: "SalaForm",
  props: {
    'gamesFormAction': {
      type: String,
      default: () => ""
    },
    'tipoGame': {
      type: Number,
      default: () => 0
    },
    'influencer': {
      type: Object,
      default: () => {}
    },
    'salas': {
      type: Array,
      default: () => []
    }
  },
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      renderTable: false,
      categorias: [],
      form: [],
      tipo_premio: [],
      activeTab: {
        sala: 0,
        limiar: 0,
        premio: 0
      }
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    opcCategorias() {

      let categorias = this.categorias
      let selected = this.form.categorias

      if (selected){
        categorias = categorias
        .filter(el => selected.findIndex(s => s.value == el.id) == -1)
      }

      return categorias
      .map(el => {
        return {
          label: el.descricao,
          value: el.id
        }
      })
    },
  },
  async created(){

    this.tipo_premio = await this.getTypesGameSubSala()

    if (this.salas){

      this.categorias = (await this.getCategorias()).data

      let loader = this.$loading.show()
      let nSala = 0
      let nLimiar = 0
      let nPremiacao = 0
      let nRanking = 0

      for (const sala of this.salas){
        let formLimiar = {}
        let formSubSalas = []
        for (const subSala of sala.subSalas) {
          let formRanking = []
          for (const ranking of subSala.ranking) {
            nRanking++
            formRanking.push(
              {
                index: nRanking,
                posicao: ranking.rnk,
                percent_posicao: ranking.percent,
                premio_produto: ranking.premio_produto,
                premio_fixo: ranking.premio_fixo,
                premio_fixo_bonus: ranking.premio_fixo_bonus
              }
            )
          }
          nPremiacao++
          if (!formLimiar[subSala.min_qtd_time]){
            nLimiar++
            formLimiar[subSala.min_qtd_time] = {
              index: nLimiar,
              limiar: subSala.min_qtd_time,
              isubsalas: 0,
              subsalas: []
            }
          }
          formLimiar[subSala.min_qtd_time].isubsalas++
          formLimiar[subSala.min_qtd_time].subsalas.push(
            {
              index: nPremiacao,
              id_sub_sala: (subSala.id_sub_sala) ? subSala.id_sub_sala : null,
              tipo: subSala.id_tipo_sub_sala,
              tipo_name: subSala.descricao_tipo_sub_sala,
              percent_sub: subSala.percent_sub_sala,
              min_qtd_time: subSala.min_qtd_time,
              irankings: formRanking.length,
              ranking: formRanking
            }
          )
        }

        for (const key in formLimiar) {
          if (formLimiar.hasOwnProperty(key)) {
            const limiar = formLimiar[key];
            formSubSalas.push(limiar)
          }
        }

        nSala++
        this.form.push({
          index: nSala,
          id_sala: (sala.id_sala) ? sala.id_sala : null,
          titulo: sala.titulo,
          descricao: sala.descricao,
          valor: sala.valor,
          percent_escala: sala.percent_escala,
          percent_sala: sala.percent_sala,
          max_qtd_time: sala.max_qtd_time,
          max_qtd_time_pssa: sala.max_qtd_time_pssa,
          limite_reserva: sala.limite_reserva,
          capitao: sala.capitao,
          clonagem: sala.clonagem,
          categorias: sala.categorias
          .map(el => {
            return {
              label: el.descricao,
              value: el.id
            }
          }),
          senha: sala.senha,
          prev_qtd_time: sala.prev_qtd_time,
          premiacao: formSubSalas
        })
      }

      this.renderTable = true
      loader.hide()
    }
  },
  watch: {
    form: {
      handler: function(newVal, oldVal) {
        this.$emit("change", newVal);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([
      "getTypesGameSubSala",
      "getCategorias"
    ]),
    addSala() {
      let index = (this.form.length + 1)
      this.form.push({
        index: index,
        titulo: null,
        descricao: null,
        valor: null,
        percent_escala: this.influencer ? this.influencer.percent_escala : null,
        percent_sala: this.influencer ? this.influencer.percent_sala : null,
        max_qtd_time: null,
        max_qtd_time_pssa: null,
        limite_reserva: null,
        capitao: 0,
        clonagem: 0,
        categorias: [],
        senha: "",
        ipremios: 0,
        premiacao: []
      });
      this.activeTab.sala = this.form.length-1
    },
    addLimiar(sala) {

      if(!sala.premiacao){
        sala.premiacao = []
      }
      sala.premiacao.push({
        index: (sala.premiacao.length + 1),
        limiar: 0,
        isubsalas: 0,
        subsalas: []
      })
      
      this.activeTab.limiar = (sala.premiacao.length - 1)
    },
    addSubSala(sala, limiar) {

      if (limiar.isubsalas < limiar.subsalas.length){

        let occurr = limiar.subsalas.length - limiar.isubsalas
        this.delSubSala(limiar, limiar.subsalas, limiar.isubsalas, occurr)

      } else if (limiar.isubsalas > limiar.subsalas.length){

        if(!limiar.subsalas){
          limiar.subsalas = []
        }

        sala.percent_escala = Number(sala.percent_escala)
        sala.percent_sala = Number(sala.percent_sala)

        let pEscala = (sala.percent_escala > 0) ? sala.percent_escala : 0
        let pSala = (sala.percent_sala > 0) ? sala.percent_sala : 0
        let percent = (100 - pEscala - pSala)

        for (const subsala of limiar.subsalas) {
          percent -= Number(subsala.percent_sub)
        }

        percent = Number(percent.toFixed(4))

        for (let index = (limiar.subsalas.length+1); index <= limiar.isubsalas; index++) {
          
          limiar.subsalas.push({
            index: index,
            tipo: 0,
            tipo_name: "",
            percent_sub: percent,
            min_qtd_time: limiar.limiar,
            irankings: 0,
            ranking: []
          })

          percent = 0
        }
      }
    },
    addRanking(premio) {

      if (premio.irankings < premio.ranking.length){

        let occurr = premio.ranking.length - premio.irankings
        this.delRanking(premio, premio.ranking, premio.irankings, occurr)

      } else if (premio.irankings > premio.ranking.length){

        if(!premio.ranking){
          premio.ranking = []
        }

        let percent = 100
        for (const ranking of premio.ranking) {
          ranking.percent_posicao = Number(ranking.percent_posicao)
          percent -= (ranking.percent_posicao > 0) ? ranking.percent_posicao : 0
        }

        for (let index = (premio.ranking.length+1); index <= premio.irankings; index++) {

          premio.ranking.push({
            index: index,
            posicao: index,
            percent_posicao: percent,
            premio_produto: "",
            premio_fixo: 0,
            premio_fixo_bonus: 0
          })

          percent = 0
        }
      }
    },
    delSala(sala, index){
      sala.splice(index,1)
      for (let i = 0; i < sala.length; i++) {
        sala[i].index = (i+1)
      }
    },
    delLimiar(limiar, index){
      limiar.splice(index,1)
      for (let i = 0; i < limiar.length; i++) {
        limiar[i].index = (i+1)
      }
    },
    delSubSala(limiar, subSala, index, occurr){
      occurr = (occurr ? occurr : 1)
      let percent = 0
      for (let i = index; i < subSala.length; i++) {
        subSala[i].percent_sub = Number(subSala[i].percent_sub)
        percent += subSala[i].percent_sub
      }
      subSala.splice(index,occurr)
      for (let i = 0; i < subSala.length; i++) {
        subSala[i].index = (i+1)
        subSala[i].percent_sub = Number(subSala[i].percent_sub)
        if (i == (subSala.length-1)){
          subSala[i].percent_sub += percent
        }
      }
      limiar.isubsalas = subSala.length
    },
    delRanking(subSala, ranking, index, occurr){
      occurr = (occurr ? occurr : 1)
      let percent = 0
      for (let i = index; i < ranking.length; i++) {
        ranking[i].percent_posicao = Number(ranking[i].percent_posicao)
        percent += ranking[i].percent_posicao
      }
      ranking.splice(index,occurr)
      for (let i = 0; i < ranking.length; i++) {
        ranking[i].index = (i+1)
        ranking[i].posicao = (i+1)
        ranking[i].percent_posicao = Number(ranking[i].percent_posicao)
        if (i == (ranking.length-1)){
          ranking[i].percent_posicao += percent
        }
      }
      subSala.irankings = ranking.length
    },
    calcPremio(sala){
      if(sala.premiacao && sala.percent_escala !== "" && sala.percent_sala !== ""){

        sala.percent_escala = Number(sala.percent_escala)
        sala.percent_sala = Number(sala.percent_sala)

        let pEscala = (sala.percent_escala > 0) ? sala.percent_escala : 0
        let pSala = (sala.percent_sala > 0) ? sala.percent_sala : 0
        let percent = (100 - pEscala - pSala)

        for (const premiacao of sala.premiacao) {
          let totalPremio = 0
          for (const subsala of premiacao.subsalas) {
            subsala.percent_sub = Number(subsala.percent_sub)
            totalPremio += subsala.percent_sub
          }
          for (const subsala of premiacao.subsalas) {
            subsala.percent_sub = Number(subsala.percent_sub)
            subsala.percent_sub = (percent * (subsala.percent_sub/totalPremio))
            subsala.percent_sub = Number(subsala.percent_sub.toFixed(4))
          }
        }
      }
    },
    updateLimiar(limiar){
      for (const subsala of limiar.subsalas) {
        subsala.min_qtd_time = limiar.limiar
      }
    },
    updatePremio(sala, limiar, premio){

      sala.percent_escala = Number(sala.percent_escala)
      sala.percent_sala = Number(sala.percent_sala)
      premio.percent_sub = Number(premio.percent_sub)

      let pEscala = (sala.percent_escala > 0) ? sala.percent_escala : 0
      let pSala = (sala.percent_sala > 0) ? sala.percent_sala : 0

      let pSub = 0
      let pSubAnt = 0
      for (const subsala of limiar.subsalas) {
        if (subsala.index <= premio.index){
          pSub += Number(subsala.percent_sub)
        }
        if (subsala.index < premio.index){
          pSubAnt += Number(subsala.percent_sub)
        }
      }
      
      let percent = (100 - pEscala - pSala)
      let saldo = percent - pSub

      if (saldo < 0){
        premio.percent_sub = (percent - pSubAnt)
        premio.percent_sub = Number(premio.percent_sub.toFixed(4))

        saldo = 0
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "O percentual das premiações deverá ser menor ou igual ao disponível da sala, deduzindo o %Escala e o %Sala.",
          type: "is-danger",
          hasIcon: true
        });
      }

      let total = pSub
      for (const subsala of limiar.subsalas) {
        if (subsala.index > premio.index){
          subsala.percent_sub = Number(subsala.percent_sub)
          if (saldo == 0){
            subsala.percent_sub = 0
          } else {
            if ((total + subsala.percent_sub + saldo) <= percent){
              subsala.percent_sub += saldo
              total += subsala.percent_sub
              subsala.percent_sub = Number(subsala.percent_sub.toFixed(4))
              total = Number(total.toFixed(4))
              saldo = 0 
            } else {
              let dif = (total + subsala.percent_sub + saldo) - percent
              subsala.percent_sub += (saldo - dif)
              total += subsala.percent_sub
              subsala.percent_sub = Number(subsala.percent_sub.toFixed(4))
              total = Number(total.toFixed(4))
              saldo = dif
              saldo = Number(saldo.toFixed(4))
            } 
          }
        }
      }
    },
    updateRanking(premio, rnk){

      let pRnk = 0
      let pRnkAnt = 0
      for (const ranking of premio.ranking) {
        if (ranking.index <= rnk.index){
          pRnk += Number(ranking.percent_posicao)
        }
        if (ranking.index < rnk.index){
          pRnkAnt += Number(ranking.percent_posicao)
        }
      }

      let saldo = 100 - pRnk

      if (saldo < 0){
        rnk.percent_posicao = (100 - pRnkAnt)
        saldo = 0
        this.$buefy.dialog.alert({
          title: "ATENÇÃO!",
          message: "O percentual dos rankings deverá ser menor ou igual a 100.",
          type: "is-danger",
          hasIcon: true
        });
      }

      let total = pRnk
      for (const ranking of premio.ranking) {
        if (ranking.index > rnk.index){
          ranking.percent_posicao = Number(ranking.percent_posicao)
          if (saldo == 0){
            ranking.percent_posicao = 0
          } else {
            if ((total + ranking.percent_posicao + saldo) <= 100){
              ranking.percent_posicao += saldo
              total += ranking.percent_posicao
              saldo = 0 
            } else {
              let dif = (total + ranking.percent_posicao + saldo) - 100
              ranking.percent_posicao += (saldo - dif)
              total += ranking.percent_posicao
              saldo = dif
            } 
          }
        }
      }
    },
    validType(premio){

      let tipoPremio = premio.tipo
      for (const tipo of this.tipo_premio) {
        if (tipo.id == tipoPremio){
          if (tipo.game_tipo.indexOf(this.tipoGame) == -1){
            premio.tipo = ""
            this.$buefy.dialog.alert({
              title: "ATENÇÃO!",
              message: "<p>Tipo de premiação não permitida para o tipo de game selecinado!</p>",
              type: "is-danger",
              hasIcon: true
            });
          } else {
            premio.tipo_name = tipo.descricao
          }
        }
      }
    }
  }
};
</script>

<style scoped>
#SalaForm .content-sub{
  padding: 1.2em;
  border: 1px solid #202020;
  background-color: rgba(0, 0, 0, 0.151);
  border-radius: 4px;
} 
#SalaForm .new-form {
  border: 1px solid #292929;
  background-color: rgba(185, 185, 185, 0.151);
}
#SalaForm .block-pos-premio {
  border-bottom: 1px solid rgba(0, 0, 0, 0.411);
}
#SalaForm .toolbar {
  position: absolute;
  right: 0;
  text-align: right;
  padding: 0;
}
#SalaForm .detail-label {
  display: inline-table; 
  font-size: 1.0em; 
  font-weight: bold; 
  margin-right: 15px;
  vertical-align: middle;
}
</style>
